import React from 'react';
import Banner from './Banner';
import styles from './Products.module.css';
import {FadeInSubText,FadeInTitle} from '../Home/FadeInText';




const Products =() =>
    {
        return(<div className={styles.homecontent}>
        <FadeInTitle text ="Welcome to RSD IT Services"/>
        <FadeInSubText text ="Empowering Your Business in the Digital World"/> 
        <Banner></Banner>
        </div>)
    }
    
export default Products;