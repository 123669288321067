// AnimatedPage.jsx
import React from 'react';
import { motion } from 'framer-motion';

const pageVariants = {
  initial: { opacity: 0, x: '400vw' },
  animate: { opacity: 1, x: 1 },
  exit: { opacity: 0, x: '100vw' },
};

const transition = { duration: 0.5 };

function AnimatedPage({ children }) {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}

export default AnimatedPage;
