import styles from './Services.module.css';
import {FadeInText, FadeInPara} from '../Home/FadeInText';
const Principles = () =>
{
    return (
        <>
    <div className={styles.servicetitle}>
        <b><FadeInText className ={styles.servicetitle} text="Why Choose RSD IT Services?"></FadeInText></b>
        </div>
        <div className = {styles.serviceDescription}>
        <FadeInPara  text="At RSD IT Services, we operate on three fundamental principles:"></FadeInPara>
          <div className={styles.homeprinciples}>
            <b><FadeInText text="Trust"></FadeInText></b>
            <FadeInPara  text="We believe in building long-term relationships with our clients, founded on honesty and transparency. You can rely on us to provide clear, actionable advice and solutions that genuinely meet your needs."></FadeInPara>
            <b><FadeInText text="Innovation"></FadeInText></b>
            <FadeInPara  text="In today’s rapidly evolving digital landscape, innovation is essential. Our team is constantly exploring new technologies and approaches to deliver cutting-edge solutions that keep your business ahead of the competition."></FadeInPara>
            <b><FadeInText text="Quality"></FadeInText></b>
            <FadeInPara  text="We are committed to delivering the highest quality in every project we undertake. From design and development to consultation and support, excellence is at the heart of everything we do."></FadeInPara>
          </div>
      </div>
      <div className={styles.servicetitle}>
      <b><FadeInText text="How We Help Your Business"></FadeInText></b>
      </div>
      <div className = {styles.serviceDescription}>
        <FadeInPara  text="At RSD IT Services, we don’t just offer services—we offer partnership. We’re here to support you at every step of your digital journey, from initial consultation to implementation and beyond. Whether you’re launching a new venture or enhancing an established business, we’re committed to helping you achieve your goals."></FadeInPara>
      </div>
      </>)
}

export default Principles;


 