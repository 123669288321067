import logo from "../images/Logo.png";
import styles from './Header.module.css';
import RSDButtonComponent from './StyledButton';

const pages = ['Home', 'Services', 'Products', 'Contact Us'];

const Header = (props) =>
{
    return( <>
   <div>
      <header>
        <nav className={styles.navbar}>
        <div className={styles.headerlogotitle}>
            <img src={logo} className={styles.applogo} alt="Logo"></img>           
            <h1 className={styles.headertext}>RSD IT SERVICES</h1>
        </div>
        <ul className={styles.navlinks}>
          {pages.map((page) => {
            
            return(
                <a href= {page === "Home"? "/": page}><RSDButtonComponent page ={page}></RSDButtonComponent> </a> )     
             })}
        </ul>
        </nav>
      </header>
    </div>
    </>
      )
}
export default Header;