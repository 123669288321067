import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import ReactDOM from 'react-dom/client';
import './index.css';
import BasePage from './BasePage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
  <BrowserRouter> 
  <AnimatePresence mode="wait">
  <Routes>   
      <Route index element={<BasePage pagename = "Home" />} />
      <Route path="Services" element={<BasePage pagename = "Services" />} />
      <Route path="Products" element={<BasePage pagename = "Products" />} />
      <Route path="Contact Us" element={<BasePage pagename = "Contact" />} />
  </Routes>
  </AnimatePresence>
</BrowserRouter>  
);