import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Services.module.css';


const BusinessApplicationDevelopment =()=>
{
    return(<>
    <Accordion>
        <AccordionSummary
      expandIcon={<ExpandMoreIcon />} className={styles.servicetitle}>
   Business Application Development
        </AccordionSummary>
    <AccordionDetails className={styles.serviceDescription}>
        <div>
        <p>At RSD IT Services, we specialize in developing tailored business applications designed to meet your organization's unique requirements.</p>
        <p>Our goal is to streamline your operations, enhance productivity, and deliver scalable solutions that drive business growth.</p>
        <b>Our Expertise Includes:</b>
        <div>
        <b>1. Custom Business Applications:</b>
        <p>Whether you need a CRM, ERP, inventory management, or financial systems, we create applications that align perfectly with your business workflows.
        </p>
        </div>
        <div>
        <b>2. Automation of Business Processes:</b>
        <p>Eliminate repetitive tasks by automating your core processes to save time, reduce errors, and increase efficiency.
        </p>
        </div>
        <div>
        <b>3. Data-Driven Solutions:</b>
        <p>We develop applications that provide real-time insights into your business performance, empowering better decision-making.
        </p>
        </div>
        <div>
        <b>4. Integration with Existing Systems:</b>
        <p>Seamlessly connect new applications with your existing software, databases, and tools to create a unified digital ecosystem.
        </p>
        </div>
        <div>
        <b>5. Business Process Automation in the Cloud</b>
        <p>Streamline and automate your workflows using cloud-based tools and technologies. We enable businesses to reduce manual work, improve accuracy, and accelerate their processes.
        </p>
        </div>
        </div>
        </AccordionDetails>
    </Accordion>
    </>)
}

export default BusinessApplicationDevelopment;