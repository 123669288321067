import {motion} from "framer-motion";
const visible = { opacity: 1, y: 0, transition: { duration: 4.0 } };
const titlevisible = { opacity: 1, y: 0, transition: { duration: 1.2 } };
const subTextVisible = { opacity: 1, y: 0, transition: { duration: 1.6 } };
const contentVisible = { opacity: 1, y: 0, transition: { duration: 4.0 } };
const titlehidden =  { opacity: 0, y: -80 };
const hidden =  { opacity: 0, y: 0 };

const FadeInText = (props) =>
{
    return(<>
    <motion.article>
      <motion.h1 initial="hidden" animate="visible" variants={{hidden,visible}} style={{margin:0, fontSize: 'x-large'}}>
        {props.text}
      </motion.h1>
      </motion.article>
    </>)
}

const FadeInTitle = (props) =>
  {
      return(<>
      <motion.article>
        <motion.h1 initial="titlehidden" animate="titlevisible" variants={{titlehidden,titlevisible}} style={{margin:0, fontSize: 'xxx-large'}}>
          {props.text}
        </motion.h1>
        </motion.article>
      </>)
  }

const FadeInSubText = (props) =>
  {
      return(<>
      <motion.article>
        <motion.div initial="titlehidden" animate="subTextVisible"  variants={{titlehidden,subTextVisible}} style={{margin:0, fontSize: 'xx-large'}}>
          {props.text}
        </motion.div>
        </motion.article>
      </>)
  }

  const FadeInPara = (props) =>
    {
        return(<>
        <motion.article>
          <motion.p initial="hidden" animate="contentVisible"  variants={{hidden,contentVisible}} style={{margin:0, fontSize: 'x-large', paddingBottom: '1rem'}}>
            {props.text}
          </motion.p>
          </motion.article>
        </>)
    }

    const FadeInServices = (props) =>
      {
          return(<>
          <motion.article>
            <motion.p initial="hidden" animate="contentVisible"  variants={{hidden,contentVisible}} style={{margin:0, fontSize: 'x-large', paddingBottom: '1rem', textDecoration:'underline'}}>
              {props.text}
            </motion.p>
            </motion.article>
          </>)
      }


export {FadeInText, FadeInSubText, FadeInPara, FadeInTitle,FadeInServices };