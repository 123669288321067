import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Services.module.css';


const CloudService =()=>
{
    return(<>
    <Accordion>
        <AccordionSummary
      expandIcon={<ExpandMoreIcon />} className={styles.servicetitle}>
   Cloud Services
        </AccordionSummary>
    <AccordionDetails className={styles.serviceDescription}>
        <div>
        <p>At RSD IT Services, we specialize in providing tailored cloud solutions to businesses of all sizes. Our cloud services are designed to improve operational efficiency, enhance scalability, and reduce costs.</p>
        <p>Whether you're looking to migrate to the cloud, manage your cloud environment, or develop cloud-based applications, we have you covered.</p>
        <b>Our Cloud Service Offerings:</b>
        <div>
        <b>1. Cloud Migration</b>
        <p>Seamlessly transition your business applications, data, and infrastructure to the cloud with minimal downtime. Our team ensures a smooth migration process with thorough planning and execution, guaranteeing data security and operational continuity.
        </p>
        </div>
        <div>
        <b>2. Cloud Hosting and Management</b>
        <p>Enjoy reliable and scalable hosting solutions for your websites, applications, and databases. We manage your cloud environment to optimize performance, ensure security, and keep costs in check, so you can focus on your core business operations.
        </p>
        </div>
        <div>
        <b>3. Cloud Security Services</b>
        <p>Protect your sensitive business data with our advanced cloud security solutions. From implementing robust access controls to monitoring threats in real-time, we ensure your cloud environment is safeguarded against cyber risks.
        </p>
        </div>
        <div>
        <b>4. App Modernization for the Cloud</b>
        <p>Transform your legacy applications into modern, cloud-native solutions. By leveraging the latest cloud technologies, we help you achieve better performance, scalability, and lower operational costs.
        </p>
        </div>
        <div>
        <b>5. Business Process Automation in the Cloud</b>
        <p>Streamline and automate your workflows using cloud-based tools and technologies. We enable businesses to reduce manual work, improve accuracy, and accelerate their processes.
        </p>
        </div>
        </div>
        </AccordionDetails>
    </Accordion>
    </>)
}

export default CloudService;