import styles from './Footer.module.css';
import logo from '../images/Logo.png';

const Footer = () =>
{
    return(
        <>
    <footer>
        <div className={styles.navbar}>
        <div>
           <h4>Contact Us</h4>
           <ul>
            <ul>RSD IT Services</ul>
            <ul>815, 160 Cherryhill Place</ul>
            <ul>London, Ontario, N6H 4M4</ul>
            <ul>Phone: (437) 679-8487</ul>
            <ul>Email: <a href="mailto:xxxxxxxxx@gmail.com">info@rsditservices.com</a></ul>
            <ul>Website: <a href="https://rsditservices.com">https://rsditservices.com</a></ul>
           </ul>
        </div>
        <div>
            <h4><a href="/Services">Our Services</a></h4>
            <ul>
            <ul><a href="/Services">Quick Online Store Setup</a></ul>
            <ul><a href="/Services">Cloud Services Management</a></ul>
            <ul><a href="/Services">Business Application Development</a></ul>
            </ul>
        </div>
        <div>
            <h4>Useful links</h4>
            <ul>
            <ul><a href="/Services">Services</a></ul>
            <ul><a href="/Products">Products</a></ul>
            <ul><a href="/Contact Us">Contact Us</a></ul>
            </ul>
        </div>
        <div>
        <img src={logo} className={styles.applogo} alt="Logo"></img>  
        </div>
        </div>
    </footer>
    </>
)
}
export default Footer