import Shopify from './Shopify';
import CloudService from './CloudService';
import Principles from './Principles';

import BusinessApplicationDevelopment from './BusinessApplicationDevelopment';

import React from 'react';

function Services()
{
    return(<>
        <Shopify></Shopify>
        <CloudService></CloudService>
        <BusinessApplicationDevelopment></BusinessApplicationDevelopment>
        <Principles></Principles>
    </>)
}

export default Services;