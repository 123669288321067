import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Services.module.css';

const Shopify = () =>
{

    return(
        <Accordion>
            <AccordionSummary
          expandIcon={<ExpandMoreIcon />} className={styles.servicetitle}>
       Quick Online Store Setup
        </AccordionSummary>
    <AccordionDetails className={styles.serviceDescription}>
    <div>
        <p><b>Take your business online in just a few hours with RSD IT Services!</b></p>
        <p>Launching your online store has never been easier. We provide all the tools and expertise you need to establish your online presence quickly and efficiently. Whether you're a small business or an established retailer, our team ensures a smooth and hassle-free setup.</p>
        <div>
        <b>1. Inventory Setup</b>
        <p>Effortlessly organize and manage your product listings.</p>       
            <li>We’ll help you upload your inventory, complete with product descriptions, images, and pricing.</li>           
            <li>Easily categorize your products for a seamless browsing experience.</li>
            <li>Manage stock levels with user-friendly tools to avoid overselling.</li>       
        </div>
        <div>
        <b>2. Payment Integration</b>
        <p>Enable secure and hassle-free transactions for your customers.</p>
            <li>We integrate trusted payment gateways like PayPal, Shopify, and more.</li>           
            <li>Provide multiple payment options (credit/debit cards, digital wallets, etc.) to suit your audience.</li>
            <li>Ensure transactions are fast, reliable, and secure with PCI-compliant solutions.</li>
        </div>
        <div>
        <b>3. E-Commerce Customization</b>
        <p>Create a store that reflects your unique brand identity.</p>
            <li>Custom themes and designs tailored to match your brand’s style and vision.</li>           
            <li>Responsive designs to ensure your store looks great on all devices.</li>
            <li>Add features like customer reviews, search filters, and promotional banners to enhance the shopping experience.</li>
        </div>
        </div>  </AccordionDetails>
    </Accordion>
    )
};

export default Shopify;