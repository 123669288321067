import React from 'react';
import { Box, Typography } from '@mui/material';
import {FadeInSubText} from '../Home/FadeInText';

const Banner = () => {
  return (
    <Box 
      sx={{
        backgroundColor: '#fffff', // Primary color
        fontFamily: "Agdasima"
      }}
    >
      <Typography variant="h3" component="div" style={{fontFamily:"Agdasima"}}>
       <FadeInSubText  text="We are working on listing our products!"/>
      </Typography>
    </Box>
  );
};

export default Banner;
