import React,{useEffect} from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Home from './Home/Home';
import Services from './Services/Services';
import Products from './Products/Products';
import Contact from './Contactus/Contact';
import Animated from './Animated';

const BasePage =(props) =>
{
useEffect(() => {
    document.title = 'RSD IT Services';
  }, []);

  const pagename = props.pagename;

  const renderPage = () => {
    switch (pagename) {
      case "Home":
        return <Home />;
      case "Services":
        return <Services />;
      case "Products":
        return <Products />;
      case "Contact":
          return <Contact />;
      default:
        return < Home/>;
    }
  };

    return(   
    <> 
    <Animated>
    <Header pagename={props.pagename}></Header>
      {renderPage()}
    <Footer></Footer>
    </Animated>
    </>
    )
}

export default BasePage;        