import React, { useState } from 'react';
import { Box, TextField, Button, Alert } from '@mui/material';
import emailjs from 'emailjs-com';
import style from './Contact.module.css';


const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', questions: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    emailjs
      .send(
        'service_5cdts3j', // Replace with your EmailJS service ID
        'template_30dmjdd', // Replace with your EmailJS template ID
        formData,
        'pxeDS7cSaxDFcu2Y7' // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          setSuccessMessage('Your message has been sent successfully!');
          setErrorMessage('');
          setFormData({ name: '', email: '', questions: '' });
        },
        (error) => {
          setErrorMessage('Failed to send the message. Please try again later.');
          setSuccessMessage('');
        }
      );
  };

  return (
    <Box
      sx={{
        maxWidth: '600px',
        margin: '50px auto',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >

     <div className={style.Contact}>Contact Us</div>

      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          label="Questions"
          name="questions"
          value={formData.questions}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: '20px' }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default Contact;
